import {
  CellId,
  FRACTIONAL_INDEX_END,
  FRACTIONAL_INDEX_START,
  fractionalIndexMidpoint95,
} from "@hex/common";
import { useCallback } from "react";

import { useStore } from "../../redux/hooks.js";
import {
  CellMP,
  hexVersionMPSelectors,
} from "../../redux/slices/hexVersionMPSlice";
import { SortableCell } from "../../util/cellLayoutHelpers";
import { useProjectContext } from "../../util/projectContext.js";

export type UseCellOrdersGetterArgs = {
  /** Whether to ignore component children cells or not*/
  ignoreComponentChildCells?: boolean;
  /** Whether to ignore block children cells or not */
  ignoreBlockChildCells?: boolean;
};

export type UseCellOrdersGetterResult = () => readonly CellMP[];

export function useCellOrdersGetter(
  args: UseCellOrdersGetterArgs = {},
): UseCellOrdersGetterResult {
  const { hexVersionId } = useProjectContext();
  const store = useStore();

  return useCallback(() => {
    let cells = hexVersionMPSelectors
      .getCellSelectors(hexVersionId)
      .selectSorted(store.getState());

    if (args.ignoreComponentChildCells) {
      cells = cells.filter((c) => c.parentComponentImportCellId == null);
    }

    if (args.ignoreBlockChildCells) {
      cells = cells.filter((c) => c.parentBlockCellId == null);
    }
    return cells;
  }, [
    args.ignoreBlockChildCells,
    args.ignoreComponentChildCells,
    hexVersionId,
    store,
  ]);
}

export function useGetOrderAfter() {
  const getOrders = useCellOrdersGetter({
    ignoreComponentChildCells: true,
  });

  return useCallback(
    (cellId?: CellId) => {
      const orders = getOrders();
      let idx = orders.findIndex((o) => o.id === cellId);
      if (idx < 0) idx = orders.length - 1;
      const afterOrder = orders[idx].order;
      const beforeOrder = orders[idx + 1]?.order;

      return fractionalIndexMidpoint95(
        afterOrder ?? FRACTIONAL_INDEX_START,
        beforeOrder ?? FRACTIONAL_INDEX_END,
      );
    },
    [getOrders],
  );
}
export function useGetOrderBefore() {
  const getOrders = useCellOrdersGetter({
    ignoreComponentChildCells: true,
  });

  return useCallback(
    (cellId?: CellId) => {
      const orders = getOrders();
      let idx = orders.findIndex((o) => o.id === cellId);
      if (idx < 0) idx = orders.length - 1;
      const afterOrder = orders[idx].order;
      const beforeOrder = orders[idx - 1]?.order;

      return fractionalIndexMidpoint95(
        beforeOrder ?? FRACTIONAL_INDEX_START,
        afterOrder ?? FRACTIONAL_INDEX_END,
      );
    },
    [getOrders],
  );
}

export function useGetCellBeforeOrder() {
  const getOrderedCells = useCellOrdersGetter({
    ignoreComponentChildCells: true,
  });

  return useCallback(
    (order: string): SortableCell | undefined => {
      const sortedCells = getOrderedCells();
      let cellToReturn: SortableCell | undefined;
      sortedCells.forEach((cell, idx) => {
        const nextCell = sortedCells[idx + 1];

        if (cell.order < order && (!nextCell || nextCell.order >= order)) {
          cellToReturn = cell;
        }
      });
      return cellToReturn;
    },
    [getOrderedCells],
  );
}

export function useGetCellAfterOrder() {
  const getOrderedCells = useCellOrdersGetter({
    ignoreComponentChildCells: true,
  });

  return useCallback(
    (order: string): SortableCell | undefined => {
      const sortedCells = getOrderedCells();
      return sortedCells.find((cell) => cell.order > order);
    },
    [getOrderedCells],
  );
}
